<template>
  <a-form
    ref="formRef"
    :model="formState" 
    :rules="rules"
    label-align="left" 
    layout="vertical" 
    @finishFailed="handleFinishFailed">
  <br/>
    <a-tabs  v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Titre">
        <a-form-item  name="titleFr">
          <a-input v-model:value="formState.titleFr" placeholder="Titre" />
        </a-form-item>        
      </a-tab-pane>
      <a-tab-pane key="2" tab="عنوان">
        <a-form-item name="titleAr">
          <a-input v-model:value="formState.titleAr" placeholder="عنوان" />
        </a-form-item>        
      </a-tab-pane>
      <a-tab-pane key="3" tab="Title">
        <a-form-item name="titleEn">
          <a-input v-model:value="formState.titleEn" placeholder="Title" />
        </a-form-item>        
      </a-tab-pane>      
  </a-tabs>
    <a-form-item label="Categorie" name="category">
      <a-select 
        v-model:value="formState.category"
      >
        <a-select-option
          v-for="category of categories"
          :key="category._id"
          :value="category._id"
        >
         {{category.title[language[activeKey?parseInt(activeKey)-1:0]]}}
        </a-select-option>
      </a-select>
    </a-form-item>
  <a-tabs  v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Contenue ">
    <a-form-item name="descFr">
      <quill-editor
       v-model:value="formState.descFr"
       style="height: 200px"
       >
       </quill-editor>
    </a-form-item>      
      </a-tab-pane>
      <a-tab-pane key="2" tab="محتوى">
    <a-form-item name="descAr">
      <quill-editor
       v-model:value="formState.descAr"
       style="height: 200px"
       >
       </quill-editor>
    </a-form-item>     
      </a-tab-pane>
      <a-tab-pane key="3" tab="Content">
    <a-form-item name="descEn">
      <quill-editor
       v-model:value="formState.descEn"
       style="height: 200px"
       >
       </quill-editor>
    </a-form-item>      
      </a-tab-pane>  
  </a-tabs>          
    <a-form-item label="Photo de couverture">
      <a-upload-dragger
      :multiple="false"
      :file-list='covImgFileList'
      :before-upload="beforeUpload"
      :custom-request="()=>{return true}"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </a-form-item>
    <a-form-item label="Attaché des fichiers">
      <a-upload-dragger
      :file-list="files"
      :before-upload="beforeUploadFiles"
      :custom-request="()=>{return true}"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </a-form-item>
    Envoyer aux abonnés
    <a-switch v-model:checked="sendToSubscribed" checked-children="oui" un-checked-children="non" />    
    <br/><br/>
    <a-form-item>
      <button class="btn btn-success btn-with-addon text-nowrap" @click="onSubmit">
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe-plus-circle"></i>
        </span>
        Modifer Le Bilan
      </button>
    </a-form-item>
  </a-form>
</template>
<script>
/* eslint-disable */
import { quillEditor } from 'vue3-quill'
import { InboxOutlined } from '@ant-design/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, toRaw, ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue';

import ApiClient from "@/services/axios"

export default defineComponent({
  components: {
    InboxOutlined,
    quillEditor,
  }, 
  setup(){
    const router = useRouter()
    const route = useRoute()
    let categories  = ref([])
    let id = route.params.id
    const formState  = reactive({
      titleFr:"",
      category:"",
      titleAr:"",
      titleEn:"",
      descFr:"",
      descAr:"",
      descEn:"",
      tags:[]
    })     
    ApiClient.post("/bilans_cetegories/filter",{
      query:{status:"active"}
    }).then((res)=>{
      categories.value = [...res.data]
    })   
    ApiClient.post("/bilans/filter",
          {
          query: {
            _id: id,
          },
              aggregation: [
                {
                  $lookup: {
                    from: "bilancategories",
                    localField: "category",
                    foreignField: "_id",
                    as: "category",
                  },
                },
                {
                  $project: {
                    _id: 1,
                    category: { $arrayElemAt: ["$category", 0] },
                    title:1,
                    converImg:1,
                    status:1,
                    seen:1,
                    desc:1,
                    files:1,
                    tags:1,
                    date:1,
                    createdAt:1
                  },
                },
              ],
        }
    )
    .then(res=>{
        const data = res.data[0]
        formState.titleFr = data.title.fr
        formState.titleAr = data.title.ar?data.title.ar:""
        formState.titleEn = data.title.en?data.title.en:""
        if (data.desc){
            formState.descFr = data.desc.fr?data.desc.fr:""
            formState.descEn = data.desc.en?data.desc.en:""
            formState.descAr = data.desc.ar?data.desc.ar:""
        }
        formState.category = data.category._id
        formState.tags = data.tags?data.tags:[]

    })
    .catch((e)=>{
        message.error("impossible de trouver le bilan")
        message.error(e.stack)
        //router.go(-1)
    })     
    //defining consts
    const rules = {                       
      category:[{
        required:true,
        message:"La catégorie est obligatoire",
        trigger: 'blur',
      }]      
    }    
    const language =  ['fr','ar','en']
    const sendToSubscribed = ref(false)
    const formRef = ref()
    const activeKey = ref()
    const coverImg = ref("")
    const files = ref([])
    const fileList = ref([])
    const covImgFileList = ref([])

    //  defining functions
    const deletetEmpty = (obj)=>{
      for (const key of Object.keys(obj))
        if (obj[key]=="") 
          delete obj[key]
      return obj
    }
    const sendToSubscribedCallBack = ()=>{ console.log("send to subscribed") }
    const onSubmit = ()=>{
      console.log(toRaw(formState))
      formRef.value
        .validate()
        .then(()=>{
          let tempData = toRaw(formState)
          let data = {
            title:{
              fr:tempData.titleFr,
              en:tempData.titleEn,
              ar:tempData.titleAr,
            },
            desc:{
              fr:tempData.descFr,
              en:tempData.descEn,
              ar:tempData.descAr,             
            },
            category:tempData.category,
            tags:tempData.tags
          }
          data.title = deletetEmpty(data.title)
          data.desc = deletetEmpty(data.desc)
          ApiClient.patch("/bilans/"+id,{
            data
          })
            .then((res)=>{
              let formData = new FormData();
              if (coverImg.value) {
                
                formData.append("coverImg", coverImg.value, id);
              }
                if(files.value.length>0){
                  for (const file of files.value) formData.append("files[]", file, "file");
                }
                ApiClient
                  .patch("/bilans/files/" +  id, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .catch(function(e)
                    {message.error("Impossible de télécharger l'image du coverture ",e.stack)}
                  ).finally(function(){
                     message.success("Bilan modifiée")
                  })
              
          })
          .catch(()=>message.error("Impossible de modifier le Bilan"))
          .finally(()=>{
             if(sendToSubscribed.value) sendToSubscribedCallBack() 
              router.push('/bilans' )
            })        
        })
        .catch((e)=>console.debug(e))
    }
    const beforeUpload = (file,_) =>{
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(
          "Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!"
        );
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("L'image doit être inférieure à 5 Mo!");
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`);
        coverImg.value = file;
        covImgFileList.value = [file]
      }
      return isJpgOrPng && isLt2M;      
    }
    const beforeUploadFiles = (file,_)=>{files.value.push(file);return true}
    const handleFinishFailed = errors => {
      console.log(errors);
    };
    return  {
      handleFinishFailed,
      sendToSubscribed,
      activeKey,
      coverImg,
      language,
      files,
      fileList,
      covImgFileList,
      formState,
      categories,
      rules,
      formRef,
      onSubmit,
      beforeUpload,
      beforeUploadFiles
    }
  }
})
</script>